import React from 'react';

// import Button from 'components/UI/Btn/';

import SocialList from 'components/Partials/SocialList';
import ImgOnline from 'components/Partials/ImgOnline';
import Telegram from 'components/UI/Btn/Telegram';

import OlgaWebP from 'assets/img/olga.webp';
import OlgaJpg from 'assets/img/olga.jpg';

const Olga = window.webPSupport ? OlgaWebP : OlgaJpg;

const Message = ({ openQuickQuestionPopup }) => {
  return (
    <div className="ba-footer__message">
      <div className="ba-footer__message-col">
        <div className="ba-footer__message-left">
          <h3 className="ba-h3">Є пропозиції?</h3>
          <p className="ba-reg">
            Хочете зв’язатися з головним лікарем - просто напишіть мені
          </p>
        </div>

        <ImgOnline src={Olga} alt="Olga" className="ba-footer__message-right" />
      </div>

      <p className="ba-footer__message-btn">
        {/* <Button onClick={openQuickQuestionPopup} mod="outline"></Button> */}

        <Telegram title={'Написати Ользі'} style={'ba-btn--outline'} />
      </p>

      <div className="ba-footer__socials">
        <SocialList />
      </div>
    </div>
  );
};

export default Message;
