import React, { useMemo } from 'react';

import Icon from 'components/UI/Icons';
import './styles.scss';

import socials from 'mocks/socials';

const Telegram = ({ title, style }) => {
  const href = useMemo(
    () => socials.filter((social) => social.type === 'telegram'),
    []
  );

  return (
    <a
      href={href[0].url}
      className={`ba-btn ${style}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <Icon name="send" />
      <span>{title}</span>
    </a>
  );
};

export default Telegram;
